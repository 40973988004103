import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.getCurrentUser();
    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (currentUser && currentUser.token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          'X-UserToken': `${currentUser.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
