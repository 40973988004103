<div class="page d-flex flex-column justify-content-center align-items-center" *ngIf="users">
  <div class="d-flex flex-column">
    <h2 class="d-flex justify-content-center title mb-5" translate="login.welcome"></h2>
    <div class="users">
      <ng-container *ngFor="let user of users">
        <div
          class="user"
          [class]="{ accountBlocked: user.accountBlocked }"
          (click)="chooseUser(user)"
        >
          <div class="d-flex justify-content-between">
            <strong>{{
              user.sharedProfile ? user.companyName : user.firstName + ' ' + user.lastName
            }}</strong>
            <ng-container *ngIf="user.accountBlocked">
              <span class="blocked-account text-end" translate="user.blocked"></span>
            </ng-container>
          </div>
          <div [translate]="'user.role.' + user.type"></div>
        </div>
      </ng-container>
    </div>
    <button
      class="add-account"
      translate="psc.addAccount"
      [routerLink]="'/psc-link-account'"
      [queryParams]="{ backUrl: 'pscAuth' }"
    ></button>
    <a (click)="logout()" class="logout">Déconnexion</a>
  </div>
</div>
