<form class="page d-flex flex-column justify-content-center align-items-center">
  <div class="d-flex flex-column group-login">
    <h2 class="d-flex justify-content-center title mb-5" translate="login.welcome"></h2>
    <button *ngIf="pscActive" class="psc-connect" (click)="connectPSC()" type="button"></button>
    <p *ngIf="pscActive" class="separator" translate="login.or"></p>
    <label class="form-label label" translate="login.mail"></label>
    <input
      id="username"
      [(ngModel)]="login"
      [ngModelOptions]="{ standalone: true }"
      [ngClass]="{ 'is-invalid': hasError }"
      class="form-control mb-3"
      placeholder="mail@mail.com"
      type="email"
      tabindex="1"
    />
    <div class="d-flex h-30">
      <label class="form-label label" translate="login.password"></label>
      <strong
        id="forgetPassword"
        class="d-flex mla form-text forget-password mb-5"
        routerLink="/forgetPassword"
        translate="login.forget"
      >
      </strong>
    </div>
    <div class="input-group">
      <input
        id="password"
        (keydown.enter)="auth()"
        [(ngModel)]="password"
        [ngModelOptions]="{ standalone: true }"
        [ngClass]="{ 'is-invalid': hasError }"
        class="form-control"
        placeholder="********"
        [type]="showPassword ? 'text' : 'password'"
        tabindex="2"
      />
      <div class="input-group-text pointer">
        <span class="material-icons eye" (click)="showPassword = !showPassword">{{
          showPassword ? 'visibility_off' : 'visibility'
        }}</span>
      </div>
    </div>
    <button
      id="submit"
      [disabled]="loading || failedAttempt === maxAttempts"
      (click)="auth()"
      class="btn btn-lg btn-primary btn-login top-49"
      translate="login.sign_in"
      tabindex="3"
    ></button>
    <div class="invalid-feedback" *ngIf="failedAttempt === maxAttempts">
      {{ lockMessage }}
    </div>
    <div
      class="invalid-feedback"
      *ngIf="!blockedError && failedAttempt !== maxAttempts"
      translate="login.error_sign_in"
    ></div>
    <div class="invalid-feedback" *ngIf="blockedError" translate="login.error_blocked"></div>
    <alert *ngIf="expire" class="mt-3" type="warning">
      <h4 class="alert-heading alert-title" translate="login.session_expire.title"></h4>
      <span class="alert-heading" translate="login.session_expire.message"></span>
    </alert>
  </div>
</form>
