import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services';

@UntilDestroy()
@Component({
  templateUrl: './forgetPassword.component.html',
  styleUrls: ['./forgetPassword.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  changePasswordSuccess;

  email;

  changePasswordError;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.changePasswordSuccess = false;
    this.changePasswordError = false;
  }

  changePassword() {
    if (!this.email) {
      this.changePasswordError = true;
      return;
    }

    this.authService
      .resetPassword(this.email)
      .pipe(
        tap(() => {
          this.changePasswordSuccess = true;
        }),
        catchError((err) => {
          this.changePasswordError = true;
          return of(err);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
