import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable()
export class CguFilesService {
  constructor(private http: HttpClient) {}

  getPdfFile(role) {
    const httpOptions = { responseType: 'blob' as 'json' };
    const invitationFilesUrl = `${environment.apiUrl}/configuration/cgu/${role}`;
    return this.http.get<any>(invitationFilesUrl, httpOptions);
  }
}
