<div class="cgu-page m-3 pe-1">
  <iframe [attr.src]="pdfFile" width="100%" height="100%" type="application/pdf"></iframe>
  <div class="d-flex flex-row-reverse mt-4">
    <button
      id="acceptCgu"
      (click)="acceptCgu()"
      class="btn btn-accept me-5"
      translate="cgu.accept"
    ></button>
    <button
      id="rejectCgu"
      (click)="rejectCgu()"
      class="btn btn-cancel me-3"
      translate="cgu.reject"
    ></button>
  </div>
</div>
