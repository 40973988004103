import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services';
import { firstValueFrom, take } from 'rxjs';
import { User } from '@app/interfaces/user.interface';

@UntilDestroy()
@Component({
  templateUrl: './pscAuth.component.html',
  styleUrls: ['./pscAuth.component.scss'],
})
export class PscAuthComponent implements OnInit {
  rpps: string;
  users: User[];

  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        code: null,
        state: null,
        session_state: null,
      },
      queryParamsHandling: 'merge',
    });

    this.users = this.route.snapshot.data.data.users;
  }

  async chooseUser(user: User) {
    if (user.accountBlocked) {
      return;
    }
    await firstValueFrom(this.authService.chooseUserPsc(user._id));
  }

  logout() {
    this.authService.logoutFromPSC().pipe(take(1)).subscribe();

    this.router.navigateByUrl('/login');
  }
}
