import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
