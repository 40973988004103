<form class="page d-flex flex-column justify-content-center align-items-center">
  <div class="d-flex flex-column group-login">
    <h2 class="d-flex justify-content-center title mb-5" translate="renewpassword.title"></h2>

    <label class="form-label label" translate="login.password"></label>
    <div class="input-group">
      <input
        id="password"
        (keydown.enter)="changePassword()"
        [(ngModel)]="password"
        [ngModelOptions]="{ standalone: true }"
        [ngClass]="{ 'is-invalid': hasError }"
        class="form-control"
        placeholder="********"
        [type]="showPassword ? 'text' : 'password'"
        tabindex="2"
      />
      <div class="input-group-text pointer">
        <span class="material-icons eye" (click)="showPassword = !showPassword">{{
          showPassword ? 'visibility_off' : 'visibility'
        }}</span>
      </div>
    </div>

    <label class="form-label label mt-3" translate="login.confirmpassword"></label>
    <div class="input-group">
      <input
        id="password2"
        (keydown.enter)="changePassword()"
        [(ngModel)]="confirmPassword"
        [ngModelOptions]="{ standalone: true }"
        [ngClass]="{ 'is-invalid': hasError }"
        class="form-control"
        placeholder="********"
        [type]="showPassword2 ? 'text' : 'password'"
        tabindex="2"
      />
      <div class="input-group-text pointer">
        <span class="material-icons eye" (click)="showPassword2 = !showPassword2">{{
          showPassword2 ? 'visibility_off' : 'visibility'
        }}</span>
      </div>

      <div class="invalid-feedback">
        {{ errorMessage }}
      </div>
    </div>
    <button
      id="submit"
      (click)="changePassword()"
      class="btn btn-lg btn-primary btn-login mt-4"
      translate="renewpassword.renewpassword"
      tabindex="3"
    ></button>
  </div>
</form>
