import { catchError, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

import { CguFilesService } from '@app/services/cguFiles.service';
import { AuthService, UserService } from '../services';

@UntilDestroy()
@Component({
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss'],
  providers: [CguFilesService],
})
export class CguComponent implements OnInit {
  pdfFile;
  user;

  constructor(
    private router: Router,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private cguFilesService: CguFilesService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    this.cguFilesService
      .getPdfFile(this.user?.type)
      .pipe(
        tap((data) => {
          const srcUrl = window.URL.createObjectURL(data);
          this.pdfFile = this.domSanitizer.bypassSecurityTrustResourceUrl(srcUrl + '#view=FitH');
        }),
        catchError(() => (document.location.href = environment.psp)),
        untilDestroyed(this),
      )
      .subscribe();
  }
  rejectCgu() {
    this.router.navigate(['/']);
  }

  acceptCgu() {
    this.userService
      .acceptCgu()
      .pipe(
        tap(() => (document.location.href = environment.psp)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
