import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../services';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
  templateUrl: './renewPassword.component.html',
  styleUrls: ['./renewPassword.component.scss'],
})
export class RenewPasswordComponent {
  password;
  confirmPassword;
  showPassword = false;
  showPassword2 = false;
  hasError = false;
  errorMessage;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  changePassword() {
    this.authService
      .changePassword(this.password, this.confirmPassword)
      .pipe(
        tap(() => {
          this.authService.redirectUser(this.authService.getCurrentUser());
        }),
        catchError((err) => {
          if (err.status === 403) {
            this.hasError = true;
            this.errorMessage = this.translate.instant('renewpassword.' + err.error?.error);
          } else if (err.status === 401) {
            this.toastr.error(this.translate.instant('login.session_expire.title'));
            this.router.navigate(['/']);
          }
          return of(err);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
