import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from '@app/login';
import { CguComponent } from '@app/cgu';
import { ForgetPasswordComponent } from '@app/forgetPassword';
import { LegalNoticeComponent } from '@app/legalNotice';
import { AuthGuard } from './services';
import { PscAuthComponent } from '@app/pscAuth';
import { AuthPSCResolve } from './services/authPsc.resolve';
import { PscLinkAccountComponent } from './psc-link-account/psc-link-account.component';
import { RenewPasswordComponent } from '@app/renewPassword';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'legalNotice', component: LegalNoticeComponent },
  {
    path: 'pscAuth',
    component: PscAuthComponent,
    resolve: {
      data: AuthPSCResolve,
    },
  },
  {
    path: 'psc-link-account',
    component: PscLinkAccountComponent,
  },
  { path: 'cgu', component: CguComponent, canActivate: [AuthGuard] },
  { path: 'renewPassword', component: RenewPasswordComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
