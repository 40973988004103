<div class="m-3 pe-1 flex-row">
  <div class="mb-1 d-flex">
    <button (click)="back()" class="ml-auto border-0">
      <img src="../../assets/image/cross.svg" />
    </button>
  </div>
  <iframe
    src="../../assets/image/mentions_legals.pdf#view=Fit"
    type="application/pdf"
    width="100%"
  ></iframe>
</div>
