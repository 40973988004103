import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services';

@UntilDestroy()
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  login = '';
  password = '';
  hasError = false;
  blockedError = false;
  expire: boolean;
  showPassword = false;
  loading = false;
  maxAttempts = 5;
  failedAttempt = 0;
  lockRemainingSeconds: number;
  pscActive;

  get lockMessage(): string {
    return this.translateService.instant('login.lockMessage', {
      seconds: this.lockRemainingSeconds,
    });
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {
    this.pscActive = environment.pscActive;
  }

  ngOnInit(): void {
    this.expire = !!this.route.snapshot.queryParams.expire;

    this.authService.clearCookieUser();
  }

  auth() {
    if (this.lockRemainingSeconds) {
      return;
    }

    this.loading = true;
    this.blockedError = false;

    this.authService
      .authentication(this.login, this.password)
      .pipe(
        catchError((err) => {
          this.hasError = true;
          this.loading = false;
          this.failedAttempt++;
          if (err.error?.error === 'Blocked') {
            this.blockedError = true;
          }
          if (this.failedAttempt === this.maxAttempts) {
            this.lockRemainingSeconds = 60;

            const interval = setInterval(() => {
              this.lockRemainingSeconds--;

              if (this.lockRemainingSeconds === 0) {
                this.failedAttempt = 0;
                this.hasError = false;
                clearInterval(interval);
              }
            }, 1000);
          }
          return of(err);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  connectPSC() {
    document.location.href =
      `${environment.pscAuth}?redirect_uri=${environment.pscRedirectUri}` +
      `&client_id=${environment.pscClientId}&response_type=code&scope=openid%20scope_all&acr_values=eidas1`;
  }
}
