import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthPSCResolve implements Resolve<any> {
  constructor(private authService: AuthService, private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { code } = route.queryParams;

    if (!code) {
      try {
        const { token } = this.authService.getCurrentUser();

        return await firstValueFrom(this.authService.listUserPsc(token));
      } catch (error) {
        this.router.navigate(['/login'], { queryParams: { expire: true } });

        return;
      }
    }

    const { users, token } = await firstValueFrom(this.authService.connectPsc(code));

    if (users.length === 1 && !users[0].accountBlocked) {
      const user = users[0];

      this.authService.putCookieUser({ ...user, token });
      this.authService.redirectUser(user);

      return;
    }

    this.authService.putCookieUser({ token });

    if (users.length >= 1) {
      return { users };
    }

    this.router.navigate(['/psc-link-account'], { queryParams: { firstAccount: true } });
  }
}
