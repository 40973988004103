export const environment = {
  production: true,
  authUrl: 'https://auth.api.preprod.1minutepourmoncoeur.fr',
  apiUrl: 'https://api.preprod.1minutepourmoncoeur.fr/1.0',
  psp: 'https://preprod.1minutepourmoncoeur.fr',
  psa: 'https://alerts.preprod.1minutepourmoncoeur.fr',
  pscActive: false,

  pscToken:
    'https://auth.esw.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/token',
  pscAuth: 'https://wallet.esw.esante.gouv.fr/auth/',
  pscRedirectUri: 'https://login.newcard.io/pscAuth',
  pscClientId: 'newcard',
  pscClientSecret: 'q48qIAlz1pf0viU4O63ueUEvDG7nkK0G',

  newcardURL: 'https://(alert.)?preprod.1minutepourmoncoeur.fr.*',
  cookieDomain: '.1minutepourmoncoeur.fr',
};
