import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  acceptCgu() {
    const { _id } = this.authService.getCurrentUser();

    return this.http.put(`${environment.apiUrl}/user/${_id}/cgu`, { cguAccepted: true });
  }
}
