import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-psc-link-account',
  templateUrl: './psc-link-account.component.html',
  styleUrls: ['./psc-link-account.component.scss'],
})
export class PscLinkAccountComponent {
  form: FormGroup;
  wrongCredentials = false;
  firstAccount = this.route.snapshot.queryParams.firstAccount;
  showPassword = false;
  blockedError = false;
  MssanteError = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
  ) {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      email: [null],
      password: [null],
    });
  }

  async connect(): Promise<void> {
    const { email, password } = this.form.value;

    try {
      this.wrongCredentials = false;
      this.blockedError = false;
      this.MssanteError = false;
      await firstValueFrom(this.authService.linkUserPsc(email, password));
    } catch (error) {
      if (error.error.error === 'Mssante') {
        this.MssanteError = true;
      } else if (error.error.error === 'Blocked') {
        this.blockedError = true;
      } else {
        this.wrongCredentials = true;
      }
    }
  }

  back(): void {
    const backUrl = this.route.snapshot.queryParams.backUrl;

    if (backUrl === 'pscAuth') {
      this.router.navigate(['/pscAuth'], { queryParams: { switchAccount: true } });

      return;
    }

    this.router.navigate(['/']);
  }
}
