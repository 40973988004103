<div class="page d-flex flex-column justify-content-center align-items-center">
  <form class="d-flex flex-column group-login" [formGroup]="form" (ngSubmit)="connect()">
    <div class="back">
      <a (click)="back()" translate="login.back"></a>
    </div>
    <h2 class="d-flex justify-content-center title mb-5 text-center" translate="psc.link"></h2>
    <p translate="psc.intro"></p>
    <label class="form-label label" translate="login.mail"></label>
    <input
      id="email"
      formControlName="email"
      class="form-control mb-3"
      [ngClass]="{ 'is-invalid': wrongCredentials || blockedError }"
      placeholder="mail@mail.com"
      type="email"
    />
    <div class="d-flex h-30">
      <label class="form-label label" translate="login.password"></label>
      <strong
        id="forgetPassword"
        class="d-flex mla form-text forget-password mb-5"
        routerLink="/forgetPassword"
        translate="login.forget"
      >
      </strong>
    </div>
    <div class="input-group">
      <input
        id="password"
        formControlName="password"
        [ngClass]="{ 'is-invalid': wrongCredentials || blockedError }"
        placeholder="********"
        class="form-control"
        [type]="showPassword ? 'text' : 'password'"
      />
      <div class="input-group-text pointer">
        <span class="material-icons eye" (click)="showPassword = !showPassword">{{
          showPassword ? 'visibility_off' : 'visibility'
        }}</span>
      </div>
    </div>
    <button
      id="submit"
      class="btn btn-lg btn-primary btn-login top-49"
      translate="login.sign_in"
    ></button>
    <p class="mt-4">
      <span translate="psc.register.main"></span>
      <a translate="psc.register.link"></a>
    </p>
    <div *ngIf="wrongCredentials" class="invalid-feedback" translate="login.error_sign_in"></div>
    <div *ngIf="blockedError" class="invalid-feedback" translate="login.error_blocked"></div>
    <div *ngIf="MssanteError" class="invalid-feedback" translate="login.error_mssante"></div>
    <alert *ngIf="firstAccount" class="mt-2" type="warning">
      <h4 class="alert-heading alert-title" translate="psc.error"></h4>
      <span class="alert-heading" translate="psc.notFound"></span>
    </alert>
  </form>
</div>
