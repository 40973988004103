<div class="d-flex flex-row page">
  <img class="mobile-logo" src="../assets/image/newcardLogo.svg" routerLink="/login" />
  <div class="d-none d-md-flex flex-column justify-content-center col-md-6 col-xl-4 bg-login">
    <div class="d-flex justify-content-center mb-5">
      <img src="../assets/image/newcardLogo.svg" routerLink="/login" />
    </div>
    <div class="d-flex justify-content-around">
      <img class="logo" src="../assets/image/1minCoeur.jpg" />
      <img class="logo" src="../assets/image/1minRein.jpg" />
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl-8 login-background">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
    <span class="mb-1 ms-4" translate="footer.copyright">
      <a
        id="legalNotice"
        class="ms-2"
        routerLink="/legalNotice"
        translate="footer.legalInformation"
      ></a>
    </span>
  </div>
</div>
