<form class="page d-flex flex-column justify-content-center align-items-center">
  <div *ngIf="changePasswordSuccess" class="d-flex flex-column group-login">
    <div class="d-flex justify-content-center mb-5 check">
      <img class="check" src="../../assets/image/check.svg" />
    </div>
    <h2
      class="d-flex justify-content-center text-center title mb-5"
      translate="forget.new_password"
    ></h2>
    <label
      class="d-flex justify-content-center goLogin"
      routerLink="/"
      translate="forget.sign_in"
    ></label>
  </div>
  <div *ngIf="!changePasswordSuccess" class="d-flex flex-column group-login">
    <h2 class="d-flex justify-content-center title mb-5" translate="forget.forget_password"></h2>
    <label class="form-label label" translate="login.mail"></label>
    <input
      id="forgetPasswordInput"
      [(ngModel)]="email"
      [ngModelOptions]="{ standalone: true }"
      [ngClass]="{ 'is-invalid': changePasswordError }"
      class="form-control"
      placeholder="mail@mail.com"
      type="email"
    />
    <div class="invalid-feedback" translate="forget.error"></div>
    <button
      id="forgetPasswordSubmit"
      (click)="changePassword()"
      class="btn btn-lg btn-primary btn-login mt-5"
      translate="forget.reset_password"
      type="submit"
    ></button>
    <label
      class="d-flex justify-content-center goLogin"
      routerLink="/"
      translate="forget.sign_in"
    ></label>
  </div>
</form>
