import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from '@app/login';
import { CguComponent } from '@app/cgu';
import { LegalNoticeComponent } from '@app/legalNotice';
import { ForgetPasswordComponent } from '@app/forgetPassword';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CookieModule } from 'ngx-cookie';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { JwtInterceptor } from './services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PscAuthComponent } from '@app/pscAuth';
import { PscLinkAccountComponent } from './psc-link-account/psc-link-account.component';
import { RouterModule } from '@angular/router';
import { RenewPasswordComponent } from '@app/renewPassword';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

@NgModule({
  declarations: [
    AppComponent,
    CguComponent,
    LoginComponent,
    ForgetPasswordComponent,
    RenewPasswordComponent,
    PscAuthComponent,
    LegalNoticeComponent,
    PscLinkAccountComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    CookieModule.forRoot(),
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
